import React, { useState, useEffect } from "react";
import "../../styles/Cart/cart.css";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import axios from "../../axios/axios";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketShopping } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import cart from "../../assets/feedback/profile-12 (1).jpeg";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Spinner from "../Auth/Spinner";
import { useCart } from "../../CartContext";
import { useGuestCart } from "../../GuestCartContext";

export default function ShoppingCart({ data, columns, cartTotal }) {
  const navigate = useNavigate();
  const { updateCartCount } = useCart();
  const { handleUpdateGuestCartItem } = useGuestCart();
  const language = sessionStorage.getItem("lang") || "en";

  const addressData =
    sessionStorage.getItem("address") !== "undefined"
      ? JSON.parse(sessionStorage.getItem("address"))
      : {};
  const [cartData, setCartData] = useState(data);

  const [CartTotal, setCartTotal] = useState(0);

  const [totalPrice, setTotalPrice] = useState(0);
  const [page, setPage] = useState(0);
  const [show, setShow] = useState(false);
  const [loadingIncrements, setLoadingIncrements] = useState(
    Array(cartData.length).fill(false)
  );

  const [loadingDecrements, setLoadingDecrements] = useState(
    Array(cartData.length).fill(false)
  );

  const [discountedTotal, setDiscountedTotal] = useState(0);
  const [loadingCheckout, setLoadingCheckout] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  useEffect(() => {
    // Calculate total price whenever cartData changes
    calculateTotalPrice();
  }, [cartData]);

  useEffect(() => {
    // Update cartData when data prop changes
    setCartData(data);
  }, [data]);

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    cartData.forEach((item) => {
      totalPrice += item.product.variant.price * item.quantity;
    });
    if (appliedPromoCode) {
      setCartTotal((prev) => ({
        ...prev,
        total: totalPrice,
        discountedTotal: totalPrice - (totalPrice * (prev.value || 0)) / 100,
      }));
      setDiscountedTotal(totalPrice - (totalPrice * CartTotal.value) / 100);
    }
    setTotalPrice(totalPrice);
  };

  const increment = (index) => {
    const updatedCartData = [...cartData];
    const updatedLoadingIncrements = [...loadingIncrements];
    updatedLoadingIncrements[index] = true;
    setLoadingIncrements(updatedLoadingIncrements);
    const updatedQuantity = (updatedCartData[index]?.quantity || 0) + 1;
    if (updatedQuantity <= updatedCartData[index].product.variant.stock) {
      updatedCartData[index] = {
        ...updatedCartData[index],
        quantity: updatedQuantity,
      };
      setCartData(updatedCartData);
      const data = {
        products: updatedCartData.map((item) => ({
          product: item.product?._id,
          variant: item.product.variant?._id,
          quantity: item.quantity,
        })),
      };
      // Send PUT request to the endpoint
      axios
        .put("/users/updateCart", data, {
          headers: { Authorization: sessionStorage.getItem("token") },
        })
        .then((response) => {
          const updatedLoadingIncrements = [...loadingIncrements];
          updatedLoadingIncrements[index] = false;
          setLoadingIncrements(updatedLoadingIncrements);
          data?.products?.forEach((product) => {
            handleUpdateGuestCartItem(
              product.product,
              product.variant,
              product.quantity,
              null,
              false
            );
          });
        })
        .catch((error) => {
          const updatedLoadingIncrements = [...loadingIncrements];
          updatedLoadingIncrements[index] = false;
          setLoadingIncrements(updatedLoadingIncrements);
          // Handle error if needed
          console.error("Error updating cart", error);
        });
    } else {
      toast.error(
        `Only ${updatedCartData[index].product.variant.stock} items left in stock`,
        {
          hideProgressBar: true,
          autoClose: 3000,
          className: "toster",
        }
      );
      const updatedLoadingIncrements = [...loadingIncrements];
      updatedLoadingIncrements[index] = false;
      setLoadingIncrements(updatedLoadingIncrements);
    }
  };

  const decrement = (index) => {
    const updatedLoadingDecrements = [...loadingDecrements];
    updatedLoadingDecrements[index] = true;
    setLoadingDecrements(updatedLoadingDecrements);
    const updatedCartData = [...cartData];
    const updatedQuantity = Math.max(
      (updatedCartData[index]?.quantity || 0) - 1,
      1
    );
    updatedCartData[index] = {
      ...updatedCartData[index],
      quantity: updatedQuantity,
    };
    setCartData(updatedCartData);
    const data = {
      products: updatedCartData.map((item) => ({
        product: item.product?._id,
        variant: item.product.variant?._id,
        quantity: item.quantity,
      })),
    };
    axios
      .put("/users/updateCart", data, {
        headers: { Authorization: sessionStorage.getItem("token") },
      })
      .then((response) => {
        const updatedLoadingDecrements = [...loadingDecrements];
        updatedLoadingDecrements[index] = false;
        setLoadingDecrements(updatedLoadingDecrements);
        data?.products?.forEach((product) => {
          handleUpdateGuestCartItem(
            product.product,
            product.variant,
            product.quantity,
            null,
            false
          );
        });
      })
      .catch((error) => {
        const updatedLoadingDecrements = [...loadingDecrements];
        updatedLoadingDecrements[index] = false;
        setLoadingDecrements(updatedLoadingDecrements);
        console.error("Error updating cart", error);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const fetchUpdatedWishlistData = async () => {
    if (sessionStorage.getItem("token")) {
      try {
        const response = await axios.get("/users/cart", {
          headers: { Authorization: sessionStorage.getItem("token") },
        });
        setCartData(response.data.cart);
        setCartTotal(response.data);
      } catch (error) {
        console.error("Error fetching updated cart data:", error);
      }
    }
  };

  const handleRemoveFromCart = async (productId, variantId) => {
    try {
      await axios.delete(`/users/removeFromCart/${productId}/${variantId}`, {
        headers: { Authorization: sessionStorage.getItem("token") },
      });

      toast.success("Product removed from cart successfully", {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster",
      });
      updateCartCount((previousCount) => previousCount - 1);

      const updatedCartData = cartData.filter(
        (item) =>
          item.product?._id !== productId ||
          item.product.variant?._id !== variantId
      );
      setCartData(updatedCartData);
      // console.log("Product removed from cart successfully");
    } catch (error) {
      toast.error(error.response?.data?.message, {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster",
      });
      console.error("Error removing product from cart:", error);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [showCheckoutInfo, setShowCheckoutInfo] = useState(true);
  const [promoCodeLoading, setPromoCodeLoading] = useState(false);

  const toggleCheckoutInfo = () => {
    setShowCheckoutInfo(!showCheckoutInfo);
  };
  const [appliedPromoCode, setAppliedPromoCode] = useState("");
  const handleSubmit = async () => {
    setPromoCodeLoading(true);
    try {
      const promoCode =
        document.getElementById("promoCodeInput")?.value || appliedPromoCode; // Get the value of the promo code input field
      const response = await axios.post(
        "/promocodes/applyPromoCode/",
        {
          code: promoCode,
          email: sessionStorage.getItem("email"),
          products: sessionStorage.getItem("token")
            ? undefined
            : cartData?.map((one) => ({
                ...one.product,
                quantity: one.quantity,
                color: one.color,
              })),
        },
        {
          headers: { Authorization: sessionStorage.getItem("token") },
        }
      );
      setAppliedPromoCode(promoCode);
      toast.success("Promo code is valid & applied successfully", {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster",
      });
      setPromoCodeLoading(false);
      handleClose();
      setDiscountedTotal(response.data.discountedTotal);
      setCartTotal(response.data);
      // Handle response from the server
      // console.log(response.data); // Log the response data
    } catch (error) {
      setPromoCodeLoading(false);
      setAppliedPromoCode(null);
      setDiscountedTotal(0);
      setCartTotal((prev) => ({
        ...prev,
        discountedTotal: 0,
        value: 0,
      }));
      // Handle errors
      toast.error(error.response?.data?.message, {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster",
      });
      console.error("Error submitting promo code:", error);
    }
  };
  //country select
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(
    addressData?.city?.country?._id
  );
  const [governorates, setGovernorates] = useState([]);
  const [selectedGovernorate, setSelectedGovernorate] = useState(
    addressData?.city?._id
  );
  const [delivery, setDelivery] = useState(addressData?.city?.delivery);
  const [selectedGovernorateId, setSelectedGovernorateId] = useState("");

  useEffect(() => {
    // Fetch countries data
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await axios.get("/zones/countries");
      setCountries(response.data.countries);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchGovernorates = async (countryId) => {
    try {
      const response = await axios.get(`/zones/${countryId}/governorates`);
      setGovernorates(response.data.governorates);
    } catch (error) {
      console.error("Error fetching governorates:", error);
    }
  };

  useEffect(() => {
    if (selectedCountry) fetchGovernorates(selectedCountry);
  }, []);

  // console.log(countries)
  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setSelectedCountry(selectedCountry);
    // console.log(selectedCountry);
    setSelectedGovernorate("");
    if (selectedCountry) {
      fetchGovernorates(selectedCountry);
    } else {
      setGovernorates([]);
    }
  };

  const handleGovernorateChange = (event) => {
    const selectedGovernorate = event.target.value; // Get the _id of the selected governorate
    // console.log(selectedGovernorate);
    setSelectedGovernorate(selectedGovernorate);
    setSelectedGovernorateId(selectedGovernorate?._id);

    setDelivery(selectedGovernorate.delivery);
  };

  const [address, setAddress] = useState({
    apartment: addressData && (addressData.apartment || ""),
    floor: addressData && (addressData.floor || ""),
    street: addressData && (addressData.street || ""),
    building: addressData && (addressData.building || ""),
    city: "",
    postalCode: addressData && (addressData.postalCode || ""),
    // country:""
  });

  // useEffect(() => {
  //   setAddress(prevAddress => ({
  //     ...prevAddress,
  //     country: selectedCountry
  //   }));
  // }, [selectedCountry]);

  useEffect(() => {
    setAddress((prevAddress) => ({
      ...prevAddress,
      city: selectedGovernorateId,
    }));
  }, [selectedGovernorate]);
  const [paymentMethod, setPaymentMethod] = useState("");

  const handleAddressChange = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleSubmitCheckout = async (e) => {
    setLoadingCheckout(true);

    e.preventDefault();
    try {
      const body = {};
      if (!sessionStorage.getItem("token")) {
        body.products = cartData.map((item) => ({
          product: item.product?._id,
          variant: item.product.variant?._id,
          quantity: item.quantity,
        }));
        body.promoCode = appliedPromoCode;
        body.firstName = sessionStorage.getItem("firstName");
        body.lastName = sessionStorage.getItem("lastName");
        body.email = sessionStorage.getItem("email");
        body.phone = sessionStorage.getItem("phone");
      }
      body.address = address;
      body.paymentMethod = paymentMethod;
      const response = await axios.post("/users/order", body, {
        headers: { Authorization: sessionStorage.getItem("token") },
      });
      setLoadingCheckout(false);
      toast.success("Your order checked out successfully", {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster",
      });
      sessionStorage.setItem("cart", 0);
      setCartData([]);
      // console.log(response)
      if (response) {
        // Check if message is 'created' and data.paymentKey exists
        if (
          response.data.message === "created" &&
          response.data.data.paymentKey
        ) {
          window.location.replace(response.data.data.paymentKey);
        } else {
          navigate("/");
        }
      } else {
        // console.log("Response is missing data or message:", response);
      }

      // console.log(response.data); // Handle success response
    } catch (error) {
      setLoadingCheckout(false);
      toast.error(error.response?.data?.message, {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster",
      });
      console.error("Error:", error); // Handle error
    }
  };

  const [editingInfo, setEditingInfo] = useState(true);

  const [formData, setFormData] = useState({
    firstName: sessionStorage.getItem("firstName") || "",
    lastName: sessionStorage.getItem("lastName") || "",
    phone: sessionStorage.getItem("phone") || "",
    email: sessionStorage.getItem("email") || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    sessionStorage.setItem(name, value);
    setFormData({ ...formData, [name]: value });
  };

  const [step, setStep] = useState(1);

  return (
    <>
      <div
        className="container"
        style={{ paddingTop: "5rem" }}
        {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
      >
        <div className="row">
          <div className="col">
            <p className="shopping">
              <span className="circleNumber me-md-3 me-1"> 1 </span>
              {language === "ar" ? "عربة التسوق" : "Shopping Cart"}
              <div className="lineCart"></div>
              <span className="circleNumber ms-md-3 ms-1"> 2 </span>
              {language === "ar" ? "الدفع" : "Checkout"}
            </p>
          </div>
        </div>
        {/* Step 1 : Shopping Cart*/}
        {step === 1 && (
          <>
            {!sessionStorage.getItem("token") && (
              <form
                className="container"
                style={{ direction: language === "ar" ? "rtl" : "ltr" }}
                onSubmit={async (e) => {
                  e.preventDefault();
                  if (editingInfo && appliedPromoCode) await handleSubmit();
                  setEditingInfo((prev) => !prev);
                }}
              >
                <div className="title mt-4 fs-3 text-center">
                  {language === "ar" ? "البيانات الشخصية" : "Personal Data"}
                </div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label className="pb-0 mb-0">
                      {language === "ar" ? "الاسم الأول" : "First Name"}
                    </Form.Label>
                    <Form.Control
                      name="firstName"
                      className="input"
                      type="text"
                      min={2}
                      required
                      placeholder={
                        language === "ar"
                          ? "أدخل الاسم الأول"
                          : "Enter your first name"
                      }
                      value={formData.firstName}
                      onChange={handleChange}
                      disabled={!editingInfo}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label className="pb-0 mb-0">
                      {language === "ar" ? "الاسم الاخير" : "Last Name"}
                    </Form.Label>
                    <Form.Control
                      className="input"
                      type="text"
                      min={2}
                      required
                      name="lastName"
                      placeholder={
                        language === "ar"
                          ? "أدخل الاسم الأخير"
                          : "Enter your last name"
                      }
                      value={formData.lastName}
                      onChange={handleChange}
                      disabled={!editingInfo}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridAddress1">
                    <Form.Label>
                      {language === "ar" ? "رقم الهاتف" : "Phone"}
                    </Form.Label>
                    <Form.Control
                      className="input"
                      placeholder={
                        language === "ar"
                          ? "أدخل رقم الهاتف"
                          : "Enter your phone"
                      }
                      value={formData.phone}
                      onChange={handleChange}
                      name="phone"
                      required
                      pattern="^(?:\+?20)?(?:0)?1[0-2]\d{8}$"
                      disabled={!editingInfo}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={0} controlId="formGridAddress1">
                    <Form.Label>
                      {language === "ar" ? "البريد الإلكتروني" : "Email"}
                    </Form.Label>
                    <Form.Control
                      className="input"
                      placeholder={
                        language === "ar"
                          ? "أدخل البريد الإلكتروني"
                          : "Enter your email"
                      }
                      value={formData.email}
                      onChange={handleChange}
                      name="email"
                      type="email"
                      required
                      disabled={!editingInfo}
                    />
                  </Form.Group>
                </Row>
                <div
                  className="btnContainer"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "1rem",
                  }}
                >
                  <button
                    style={{ width: "auto" }}
                    className="custom-btn"
                    type="submit"
                  >
                    <span>
                      {editingInfo
                        ? language === "ar"
                          ? "حفظ"
                          : "Save"
                        : language === "ar"
                        ? "تعديل"
                        : "Edit"}
                    </span>
                  </button>
                </div>
              </form>
            )}
            <div className="row mt-5">
              <div className="col-md-8">
                {" "}
                <TableContainer>
                  <Table>
                    <TableBody>
                      {cartData.map((row, index) => (
                        <TableRow key={row.product?._id}>
                          <TableCell>
                            <button
                              className="custom-btnClose"
                              onClick={() => {
                                if (sessionStorage.getItem("token"))
                                  handleRemoveFromCart(
                                    row.product?._id,
                                    row.product?.variant?._id
                                  );
                                else {
                                  handleUpdateGuestCartItem(
                                    row.product?._id,
                                    row.product?.variant?._id,
                                    0
                                  );
                                }
                              }}
                            >
                              {" "}
                              <span> X</span>
                            </button>
                          </TableCell>
                          {columns.map((column) => (
                            <TableCell key={column.Header}>
                              {typeof column.accessor === "function"
                                ? column.accessor(row)
                                : row[column.accessor]}
                            </TableCell>
                          ))}
                          <TableCell>
                            {/* Name column */}
                            <div
                              style={{
                                overflow: "hidden",
                                fontSize: "16px",
                              }}
                            >
                              {language === "ar"
                                ? row?.product?.nameAr
                                : row?.product?.name}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div
                              className="quantity"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className="spinner-container"
                                style={{
                                  position: "absolute",
                                  display:
                                    loadingDecrements[index] ||
                                    loadingIncrements[index]
                                      ? "flex"
                                      : "none",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent background to indicate loading
                                  zIndex: 999, // Ensure the spinner is above other elements
                                }}
                              >
                                <Spinner />
                              </div>
                              <div
                                className="dec qtybutton"
                                onClick={() => decrement(index)}
                              >
                                -
                              </div>
                              <input
                                className="quantityCart"
                                type="text"
                                value={cartData[index]?.quantity || 1}
                                readOnly
                              />
                              <div
                                className="inc qtybutton"
                                onClick={() => increment(index)}
                              >
                                +
                              </div>
                            </div>
                          </TableCell>

                          <TableCell>
                            {/* Price column */}
                            <div
                              style={{
                                overflow: "hidden",
                                fontSize: "16px",
                              }}
                            >
                              {(
                                row?.product?.variant?.price *
                                (cartData[index]?.quantity || 1)
                              ).toLocaleString() + " "}
                              {language === "ar" ? "ج م" : "EGP"}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <div className="row">
                  <div className="couponBox">
                    <div
                      className="btnContainer"
                      style={{ display: "flex", justifyContent: "center" }}
                      onClick={handleShow}
                    >
                      <button className="custom-btn mt-md-0 mt-3">
                        <span>
                          {" "}
                          <i
                            className="fa-solid fa-ticket "
                            style={{ marginRight: "1rem" }}
                          ></i>
                          {language === "ar"
                            ? "هل لديك كود خصم"
                            : "Have a coupon code?"}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row summaryBox mx-md-0 mx-3">
                  <div className="summaryContent">
                    <p className="summaryTitle">
                      {language === "ar" ? "الملخص" : "Summary"}
                    </p>
                    {cartTotal.promoCodeApplied ? (
                      <>
                        <p className="summaryTotal">
                          {language === "ar" ? "المجموع قبل" : "Total before"}
                          <span className="priceShop">
                            {cartTotal.total.toLocaleString()}{" "}
                            {language === "ar" ? "ج م" : "EGP"}
                          </span>
                        </p>
                        <p className="summaryTotal">
                          {language === "ar" ? "الإجمالي بعد" : "Total after"}

                          <span className="money">
                            {cartTotal.discountedTotal.toLocaleString()}{" "}
                            {language === "ar" ? "ج م" : "EGP"}
                          </span>
                        </p>
                      </>
                    ) : discountedTotal > 0 ? (
                      <p className="summaryTotal">
                        {language === "ar" ? "إجمالي قبل" : "Total before"}
                        <span className="priceShop">
                          {totalPrice.toLocaleString()}{" "}
                          {language === "ar" ? "ج م" : "EGP"}
                        </span>
                      </p>
                    ) : (
                      <p className="summaryTotal">
                        {language === "ar" ? "الإجمالي الكلي" : "Total"}{" "}
                        <span className="money">
                          {totalPrice.toLocaleString()}{" "}
                          {language === "ar" ? "ج م" : "EGP"}
                        </span>
                      </p>
                    )}
                    {discountedTotal > 0 && (
                      <p className="summaryTotal">
                        {language === "ar" ? "الإجمالي بعد" : "Total after"}

                        <span className="money">
                          {discountedTotal.toLocaleString()}{" "}
                          {language === "ar" ? "ج م" : "EGP"}
                        </span>
                      </p>
                    )}
                    <div
                      className="btnContainer"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "1rem",
                      }}
                    >
                      <button
                        style={{ width: "auto" }}
                        className="custom-btn"
                        onClick={() => {
                          // toggleCheckoutInfo();
                          setStep((prev) => prev + 1);
                          fetchUpdatedWishlistData();
                        }}
                      >
                        <span>{language === "ar" ? "الدفع" : "Checkout"}</span>
                      </button>
                    </div>
                    <p
                      className="text-center pt-4"
                      style={{ color: "#e23b26" }}
                    >
                      <a
                        href="/shop"
                        style={{
                          textDecoration: "none",
                          color: "var(--main-color)",
                        }}
                      >
                        {language === "ar"
                          ? "الاستمرار بالتسوق"
                          : "Continue Shopping"}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* Step 2 : Checkout*/}
        {step === 2 && (
          <div className="row checkoutInfo">
            <p className="yourInfo">
              {" "}
              {language === "ar" ? "المعلومات الخاصة بك" : "Your Info"}
            </p>

            <form className="row" onSubmit={handleSubmitCheckout}>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col">
                    <FormControl
                      variant="standard"
                      className="chooseCountry"
                      style={{ width: "80%", marginBottom: "2%" }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        {language === "ar" ? "الدولة" : "Country"}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        label="Country"
                        required
                      >
                        {/* <MenuItem value="">None</MenuItem> */}
                        {countries.map((country) => (
                          <MenuItem key={country?._id} value={country?._id}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <FormControl
                      className="chooseGovernment"
                      variant="standard"
                      style={{ width: "80%", marginBottom: "2%" }}
                    >
                      <InputLabel id="governorate-label">
                        {language === "ar" ? "محافظة" : "Governorate"}
                      </InputLabel>
                      <Select
                        labelId="governorate-label"
                        id="governorate-select"
                        value={selectedGovernorate}
                        onChange={handleGovernorateChange}
                        label="Governorate"
                        disabled={!selectedCountry}
                        required
                      >
                        {governorates.map((governorate) => (
                          <MenuItem key={governorate?._id} value={governorate}>
                            {governorate.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="row">
                  <div className="w-100">
                    <div
                      className="form__group field w-100"
                      style={{ width: "80%" }}
                    >
                      <input
                        type="input"
                        className="form__field w-100"
                        placeholder="Street"
                        name="street"
                        required
                        value={address.street}
                        onChange={handleAddressChange}
                      />
                      <label htmlFor="street" className="form__label">
                        {language === "ar" ? "شارع" : " Street"}
                      </label>
                    </div>
                  </div>
                </div>{" "}
                <div className="row">
                  <div className="col">
                    <div className="form__group field">
                      <input
                        type="input"
                        className="form__field"
                        placeholder="building"
                        required
                        name="building"
                        value={address.building}
                        onChange={handleAddressChange}
                      />
                      <label htmlFor="building" className="form__label">
                        {language === "ar" ? "عمارة" : "building"}
                      </label>
                    </div>
                  </div>{" "}
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form__group field">
                      <input
                        type="number"
                        className="form__field"
                        placeholder="Apartment"
                        required
                        name="apartment"
                        value={address.apartment}
                        onChange={handleAddressChange}
                      />
                      <label htmlFor="apartment" className="form__label">
                        {language === "ar" ? "شقة" : " Apartment"}
                      </label>
                    </div>
                  </div>
                </div>{" "}
                <div className="row">
                  <div className="col">
                    <div className="form__group field">
                      <input
                        type="number"
                        className="form__field"
                        placeholder="floor"
                        required
                        name="floor"
                        value={address.floor}
                        onChange={handleAddressChange}
                      />
                      <label htmlFor="floor" className="form__label">
                        {language === "ar" ? "الدور" : " Floor"}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form__group field">
                      <input
                        type="number"
                        className="form__field"
                        placeholder="postalCode"
                        required
                        name="postalCode"
                        value={address.postalCode}
                        onChange={handleAddressChange}
                      />
                      <label htmlFor="postalCode" className="form__label">
                        {language === "ar" ? "رمز بريدي" : " Postal Code"}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <FormControl
                      className="paymentMethodCheckOut"
                      variant="standard"
                      style={{ width: "80%", marginBottom: "2%" }}
                    >
                      <InputLabel id="payment-label">
                        {language === "ar" ? "طريقة الدفع" : "Payment method"}
                      </InputLabel>
                      <Select
                        labelId="payment-label"
                        id="payment-select"
                        value={paymentMethod}
                        onChange={handlePaymentMethodChange}
                        required
                      >
                        <MenuItem value="cod">
                          {language === "ar"
                            ? "الدفع عند الاستلام"
                            : " Cash on delivery"}
                        </MenuItem>
                        <MenuItem value="card">
                          {language === "ar" ? "بطاقة إئتمان" : "Credit Card"}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="row"></div>
              </div>
              <div className="col-lg-4 ms-lg-0 ms-1 ps-md-0 ps-4">
                {" "}
                <div className="row summaryBox">
                  <div className="summaryContent">
                    <p className="summaryTitle">
                      {language === "ar"
                        ? "ملخص الطلب الخاص بك"
                        : "Your Order Summary"}
                    </p>
                    {cartData?.map((product, index) => (
                      <p className="summaryTotal summaryItem" key={index}>
                        <p className="productName">
                          {language === "ar"
                            ? product.product.nameAr
                            : product.product.name}
                        </p>

                        <span className="money productPrice">
                          {(
                            product?.product?.variant?.price * product?.quantity
                          ).toLocaleString()}{" "}
                          {language === "ar" ? "ج م" : "EGP"}
                        </span>
                      </p>
                    ))}

                    <hr />
                    <p className="summaryTotal">
                      {language === "ar" ? "الإجمالي الفرعي" : "Subtotal"}
                      <span className="money">
                        {CartTotal &&
                          CartTotal?.discountedTotal.toLocaleString()}{" "}
                        {language === "ar" ? "ج م" : "EGP"}
                      </span>
                    </p>

                    {delivery && (
                      <>
                        <hr />

                        <p className="summaryTotal">
                          {language === "ar" ? "الشحن" : "Shipping"}
                          <span className="money">{delivery} EGP</span>
                        </p>
                      </>
                    )}

                    <hr />
                    <p className="summaryTotal">
                      {language === "ar" ? "الإجمالي الكلي" : " Total"}
                      {delivery ? (
                        <span className="money">
                          {(
                            CartTotal && CartTotal?.discountedTotal + delivery
                          ).toLocaleString()}{" "}
                          {language === "ar" ? "ج م" : "EGP"}
                        </span>
                      ) : (
                        <span className="money">Select Delivery Location</span>
                      )}
                    </p>
                    <hr />
                    {/*   <p className="summaryTotal">
                      Payment<span className="money">Credit Card</span>
                    </p> */}
                    <div
                      className="btnContainer"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "1rem",
                      }}
                    >
                      <button
                        style={{ width: "auto" }}
                        className="custom-btn"
                        type="submit"
                      >
                        <span>
                          {loadingCheckout ? (
                            <Spinner />
                          ) : language === "ar" ? (
                            "الدفع"
                          ) : (
                            "Checkout"
                          )}{" "}
                        </span>
                      </button>
                    </div>
                    <p
                      className="text-center pt-4"
                      style={{ color: "#e23b26" }}
                    >
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => setStep(1)}
                      >
                        {language === "ar"
                          ? "العودة إلى عربة التسوق"
                          : "Back to cart"}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      {/* Add Promo Code */}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {language === "ar" ? "أضف كود  الخصم" : "Apply your promo code"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                id="promoCodeInput"
                autoFocus
                placeholder={appliedPromoCode || "Enter promo code"}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="custom-btn" onClick={handleSubmit}>
            <span>
              {promoCodeLoading ? (
                <Spinner />
              ) : language === "ar" ? (
                "حفظ"
              ) : (
                "Save"
              )}{" "}
            </span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
