import React from "react"
import ReactDOM from "react-dom/client"
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'
import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css"
import App from "./App"
import { BrowserRouter as Router } from "react-router-dom"
import "./assets/fonts/Barlow-Black.ttf"
import "./assets/fonts/Barlow-BlackItalic.ttf"
import "./assets/fonts/Barlow-Bold.ttf"
import "./assets/fonts/Barlow-BoldItalic.ttf"
import "./assets/fonts/Barlow-ExtraBold.ttf"
import "./assets/fonts/Barlow-ExtraBoldItalic.ttf"
import "./assets/fonts/Barlow-ExtraLight.ttf"
import "./assets/fonts/Barlow-ExtraLightItalic.ttf"
import "./assets/fonts/Barlow-Italic.ttf"
import "./assets/fonts/Barlow-Light.ttf"
import "./assets/fonts/Barlow-LightItalic.ttf"
import "./assets/fonts/Barlow-Medium.ttf"
import "./assets/fonts/Barlow-MediumItalic.ttf"
import "./assets/fonts/Barlow-Regular.ttf"
import "./assets/fonts/Barlow-SemiBold.ttf"
import "./assets/fonts/Barlow-SemiBoldItalic.ttf"
import "./assets/fonts/Barlow-Thin.ttf"
import "./assets/fonts/Barlow-ThinItalic.ttf"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { WishlistProvider } from './WishlistContext';
import { CartProvider } from './CartContext.js';
import { GuestCartProvider } from './GuestCartContext.js';

const theme = createTheme({
  palette: {
    primary: {
      main: "#e23b26",
    },
    secondary: {
      main: "#f8c6b8",
    },
    dark: {
      main: "#e23b26",
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  // <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <WishlistProvider>
          <CartProvider>
            <GuestCartProvider>
              <App />
            </GuestCartProvider>
          </CartProvider>
        </WishlistProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ThemeProvider>
    </Router>
  // </React.StrictMode>
)
